<script>
/**
 * Sidebar component
 */
export default {
	data() {
		return {
			menuItems:[],
			userData:"",
			menu:[],
		};
	},
	props: {
		type: {
			type: String,
			required: true,
		},
		width: {
			type: String,
			required: true,
			default: '250px'
		},
	},
	components: {
	},
	created() {
		var menuData = localStorage.getItem('menus')
		if (menuData === null) {
			this.getMenuList()
		} else {
			this.setMenuItems()
		}
	},
	mounted: function () {
		// var menuData = JSON.parse(localStorage.getItem('menus'))
		// console.log(menuData.length)
		// if (menuData === null) {
		// 	this.getMenuList()
		// } else {
		// 	this.setMenuItems()
		// }
		// eslint-disable-next-line no-unused-vars
	},
	methods: {
		getMenuList() {
			this.axios.get("menuList").then((result) => {
				console.log(result.data)
				localStorage.setItem('menus', JSON.stringify(result.data.data))
				this.setMenuItems()
			})
		},
		setMenuItems: function() {
			let email = this.$storageData.email
			var menuData = JSON.parse(localStorage.getItem('menus'))
			var menuObjects
			this.menu = [
				menuObjects = {
					// href: menuData[i].link,
					title: this.$storageData.name,
					icon: "dripicons-gear",
					child: [
						// {
						//	href: menuData[i].subItems[x].link,
						// 	title: "Settings",
						// 	class: 'menuSubTitle'
						// },
						{
							//href: menuData[i].subItems[x].link,
							title: "Logout",
							href: "/logout",
							class: 'menuSubTitle'
						}
					],
					class: 'menuTitle'
					// child:
				},
			];
			if (email === 'sangeetha@mobcast.in' || email === 'ashwini@mobcast.in') {
				this.menu.push({
					title: 'Dashboard',
					icon: 'uil-home-alt',
					class: 'menuTitle',
					href: '/clients',
				},)
			}
			for(var i=0; i < menuData.length;i++) {
				// console.log(menuData[i].isTitle);
				if (menuData[i].subMenu.length > 0) {
					var child = [];
					for (var x = 0; x < menuData[i].subMenu.length; x++) {
						// console.log()
						var tempChildObj = {
							href: menuData[i].subMenu[x].link,
							title: menuData[i].subMenu[x].label,
							class: 'menuSubTitle'
						};
						child.push(tempChildObj)
					}
					menuObjects = {
						// href: menuData[i].link,
						title: menuData[i].label,
						icon: menuData[i].icon,
						child: child,
						class: 'menuTitle'
						// child:
					}
				} else if (menuData[i].isTitle == true) {
					//alert()
					menuObjects = {
						//href: menuData[i].link,
						title: menuData[i].label,
						class: 'menuHeader',
						// icon: menuData[i].icon,
					}
				} else {
					menuObjects={
						href: menuData[i].link,
						title: menuData[i].label,
						icon: menuData[i].icon,
						class: 'menuTitle',
						badge:menuData[i].badge,
					}
				}
				// console.log(menuData[i].label)
				this.menu.push(menuObjects)
			}
		},
	}
};
</script>
<template>
	<sidebar-menu :menu="menu" theme="white-theme" class="scroll"/>
</template>
<style>
	.v-sidebar-menu {
		width:250px;
	}
	::-webkit-scrollbar {
		width: 3px !important;
		background-color: #F5F5F5 !important;
	}
	::-webkit-scrollbar-thumb {
		border-radius: 10px !important;
		background-color: #e0e0e0 !important;
		border: 2px solid #e0e0e0 !important;
	}
	.v-sidebar-menu.vsm_white-theme.vsm_expanded .vsm--item_open .vsm--link_level-1 {
		background-color: #f7e7e5 !important;
		color: #EB455F !important;
	}
	.v-sidebar-menu.vsm_white-theme.vsm_expanded .vsm--item_open .vsm--link_level-1 .vsm--icon {
		color: #EB455F !important;
	}
	.v-sidebar-menu.vsm_white-theme.vsm_expanded .vsm--item_open .vsm--link_level-1 .vsm--icon {
		background-color: #bbc5d6;
		color: #1e1e21;
	}
	.v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_exact-active, .v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_active {
		box-shadow:none;
	}
	.v-sidebar-menu.vsm_white-theme .vsm--link_level-1 .vsm--icon {
		background-color: transparent !important;
	}
	.menuTitle{
		color: #7b8190 !important;
		position: relative !important;
		font-size: 15px !important;
		font-weight: 500 !important;
		transition: all .4s !important;
		margin: 0 10px !important;
		border-radius: 3px !important;
	}
	.v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_exact-active .vsm--icon, .v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_active .vsm--icon {
		color: #EB455F !important;
	}
	.menuSubTitle {
		padding: 6px 24px 6px 51px !important;
		font-weight: 400 !important;
		color: #7b8190 !important;
		margin: 0 !important;
		background-color: transparent !important;
		font-size: 14px !important;
	}
	.v-sidebar-menu.vsm_white-theme .vsm--dropdown .vsm--list {
		background-color: transparent;
	}
	.v-sidebar-menu .vsm--link_exact-active, .v-sidebar-menu .vsm--link_active {
		color: #EB455F !important;
	}
	.v-sidebar-menu.vsm_white-theme .vsm--toggle-btn {
		display: none !important;
	}
	.menuHeader {
		letter-spacing: .05em  !important;
		pointer-events: none !important;
		cursor: default !important;
		font-size: 11px !important;
		text-transform: uppercase !important;
		color: #EB455F !important;
		font-weight: 600 !important;
	}
	td, th {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 200px;
	}
	/* padding:0.7rem 0.5rem !important; */
	.multiselect__select {
		z-index: 1;
	}
	.table th {
		text-align: center;
		min-width: 95px;
		white-space: normal;
		vertical-align: middle;
	}
	.table > :not(caption) > * > * {
		padding: 0.25rem 0.3rem !important;
	}
</style>