
<script>
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

Vue.use(VueAxios, axios);
Vue.prototype.$storageData = JSON.parse(localStorage.getItem('user'));
Vue.prototype.$placeholder = 'https://cloudkitch1.s3.us-east-2.amazonaws.com/user_documents/fdfa6545-1a44-45f3-a0d1-f9db559817d9.png';
Vue.prototype.$userPlaceholder = 'https://cloudkitch1.s3.us-east-2.amazonaws.com/user_documents/7a83eea4-f901-4650-8255-7b31b4dc11f1.png';

export default {
	props: {
		title: {
			type: String,
			default: "",
		},
		items: {
			type: Array,
			default: () => {
				return [];
			},
		},
	},
	data() {
		return{
			
		}
	}
};
</script>
<template>
	<!-- start page title -->
	<div class="row">
		<div class="col-12">
			<div class="page-title-box d-flex align-items-center justify-content-between">
				<h4 class="mb-0">{{ title }}</h4>
				<!-- <div class="alertMsg">
					<b-alert v-model="showDismissibleAlert" :variant="alertvariant" dismissible><h5>Success! </h5>Corporate Registered Successfully!{{$alertText}}</b-alert></div> -->
				<!-- <div class="page-title-right">
					<b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
				</div> -->
			</div>
		</div>
	</div>
	<!-- end page title -->
</template>
<style>
.alertMsg{
	position: fixed;
	right: 0px;
	z-index: 500;
	top: 79px;
	width: 22%;
}
</style>
